import React, { useEffect, useState } from "react";
import "./dashboard.css";
import verifyAuth from "../../services/verifyAuth/verifyAuth";
import HeaderBar from "../../components/HeaderBar/HeaderBar";
import DashboardVideoSlider from "../../components/VideoSliderElements/DashboardVideoSlider";
import CoursesInProgress from "../../components/CoursesInProgress/CoursesInProgress";
import CoursesRecommended from "../../components/CoursesRecommended/CoursesRecommended";
import CourseList from "../../components/CourseList/CourseList";
import {
  getCoursesInProgressRequest,
  getCoursesRecommendedRequest,
  getAreasAndCoursesRequest,
  getAllOriginalsCoursesRequest,
} from "../../services/dashboardService/dashboard.service";
import getStudentInfos from "../../services/getStudentInfos/getStudentInfos";
import LoadingSection from "../../components/LoadingElements/LoadingSection";
import { Toastify } from "../../components/Toastify/Toastify";
import MobileNavigationBar from "../../components/MobileNavigationBar/MobileNavigationBar";
import { getAllStudentCourses } from "../../services/courseService/course.service";
import { certifier } from "../../mocks/certifier";

function Dashboard() {
  const [isLoading, setIsLoading] = useState(true);
  const [coursesRecommended, setCoursesRecommended] = useState([]);
  const [coursesInProgress, setCoursesInProgress] = useState([]);
  const [studentCourses, setStudentCourses] = useState(undefined);
  const [areasAndCoursesRequest, setAreasAndCoursesRequest] = useState([]);
  const [allOriginalsCourses, setAllOriginalsCourses] = useState([]);
  const [windowWidth, setWindowWidth] = useState(0);
  const [navBar, setNavBar] = useState(0);

  useEffect(() => {
    const fetchDashboardInfo = async () => {
      setIsLoading(true);
      try {
        const student = await getStudentInfos();
        // setCoursesInProgress --- START
        const arrayCourses = await getCoursesInProgressRequest({
          sessionId: student.sessionId,
        });
        if (arrayCourses.status !== 200) {
          Toastify(
            "error",
            "Erro ao buscar informações de cursos em progresso."
          );
          console.error(
            "Erro ao buscar informações de cursos em progresso - 1:",
            arrayCourses.status
          );
        }
        // setCoursesInProgress --- END
        // getAllStudentCourses --- START
        const arrayStudentCourses = await getAllStudentCourses({
          sessionId: student.sessionId,
        });
        if (arrayStudentCourses.status !== 200) {
          Toastify("error", "Erro ao buscar informações de seus cursos.");
          console.error(
            "Erro ao buscar informações de seus cursos - 1:",
            arrayStudentCourses.status
          );
        }
        // getAllStudentCourses --- END
        // setCoursesRecommended --- START
        const arrayCoursesRecommended = await getCoursesRecommendedRequest({
          sessionId: student.sessionId,
          viewAll: false,
        });
        if (arrayCoursesRecommended.status !== 200) {
          Toastify(
            "error",
            "Erro ao buscar informações de cursos recomendados."
          );
          console.error(
            "Erro ao buscar informações de cursos recomendados - 2:",
            arrayCoursesRecommended.status
          );
        }
        // setCoursesRecommended --- END
        // setAreasAndCoursesRequest --- START
        const arrayAreasAndCourses = await getAreasAndCoursesRequest({
          sessionId: student.sessionId,
        });
        if (arrayAreasAndCourses.status !== 200) {
          Toastify("error", "Erro ao buscar informações de áreas e cursos.");
          console.error(
            "Erro ao buscar informações de áreas e cursos - 3:",
            arrayAreasAndCourses
          );
        }
        // setAreasAndCoursesRequest --- END
        // setOriginalsCourseRequest --- START
        if (certifier.generalSettings.foldername === "faculdadelibano") {
          const arrayAllOriginalsCourses = await getAllOriginalsCoursesRequest({
            sessionId: student.sessionId,
          });
          if (arrayAllOriginalsCourses.status !== 200) {
            Toastify("error", "Erro ao buscar informações de cursos originais.");
            console.error(
              "Erro ao buscar informações de cursos originais - 4:",
              arrayAllOriginalsCourses
            );
          }
          try {
            if (arrayAllOriginalsCourses.data !== undefined) {
              setAllOriginalsCourses([arrayAllOriginalsCourses.data]);
            } else {
              Toastify("error", "Erro ao processar itens da dashboard: #05");
            }
          } catch {
            Toastify("error", "Erro ao processar itens da dashboard: #06");
          }
        }
        // setOriginalsCourseRequest --- END
        try {
          setCoursesInProgress(arrayCourses.data);
        } catch {
          Toastify("error", "Erro ao processar itens da dashboard: #01");
        }
        try {
          let allStudentCourses = [];
          if (
            arrayStudentCourses.data.coursesPosGraduation &&
            arrayStudentCourses.data.coursesPosGraduation.length > 0
          ) {
            allStudentCourses = allStudentCourses.concat(
              arrayStudentCourses.data.coursesPosGraduation
            );
          }
          if (
            arrayStudentCourses.data.extraCourses &&
            arrayStudentCourses.data.extraCourses.length > 0
          ) {
            allStudentCourses = allStudentCourses.concat(
              arrayStudentCourses.data.extraCourses
            );
          }
          setStudentCourses(allStudentCourses);
        } catch {
          Toastify("error", "Erro ao processar itens da dashboard: #02");
        }
        try {
          setCoursesRecommended(arrayCoursesRecommended.data);
        } catch {
          Toastify("error", "Erro ao processar itens da dashboard: #03");
        }
        try {
          setAreasAndCoursesRequest(arrayAreasAndCourses.data);
        } catch {
          Toastify("error", "Erro ao processar itens da dashboard: #04");
        }
        const navBar = document.getElementById("mobile-navigation");
        if (navBar) setNavBar(navBar.offsetHeight);
      } catch (error) {
        Toastify("error", "Erro generalizado ao buscar cursos na dashboard.");
        console.error("Erro ao buscar cursos na dashboard: ", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchDashboardInfo();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <main
      style={{ marginBottom: `${windowWidth <= 1024 ? navBar : 0}px` }}
      className="flex flex-col justify-center w-full overflow-x-hidden bg-backgroundOne items-center text-textPrimary"
    >
      <div
        className={`w-full justify-center items-center overflow-x-hidden ${
          windowWidth < 1024
            ? "overflow-y-scroll custom-dark-scroll custom-transparent-scroll-background"
            : "flex flex-col"
        }`}
      >
        <HeaderBar
          courseNavigation={true}
          disableBackButton
          actualPage={"Início"}
        />
        <MobileNavigationBar actualPage="Aulas" />
        <LoadingSection isLoading={isLoading} />
        {!isLoading && <DashboardVideoSlider />}
        <section className="flex flex-col max-w-[1170px] justify-center items-center h-auto max-lg:px-[5vw] max-lg:max-w-full">
          <section className="w-full flex flex-col gap-10 mb-10 max-lg:overflow-hidden max-lg:gap-8">
            {coursesInProgress && coursesInProgress.length > 0 && (
              <section className="w-full flex">
                <CoursesInProgress
                  courses={coursesInProgress}
                  routeRedirect={"/studentCourses"}
                />
              </section>
            )}

            {/* {studentCourses && studentCourses.length > 0 && (
              <section className="w-full flex justify-center">
                <CourseList area={{areaName: "Meus cursos", specifiedRedirect: "/studentCourses", availableCourses: true, courses: studentCourses}} />
              </section>
            )} */}

            {coursesRecommended && coursesRecommended.length > 0 && (
              <section className="w-full flex justify-center">
                <CoursesRecommended courses={coursesRecommended} />
              </section>
            )}

            {areasAndCoursesRequest &&
              areasAndCoursesRequest.length > 0 &&
              areasAndCoursesRequest.map((area, index) => (
                <section key={index} className="w-full flex justify-center">
                  <CourseList area={area} />
                </section>
              ))}

            {allOriginalsCourses &&
              allOriginalsCourses.length > 0 &&
              allOriginalsCourses.map((area, index) => (
                <section key={index} className="w-full flex justify-center">
                  <CourseList area={area} />
                </section>
              ))}
          </section>
        </section>
      </div>
    </main>
  );
}

export default verifyAuth(Dashboard);
